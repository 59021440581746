<template>
  <!--begin::Wrapper-->
  <div
    class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto my-auto"
  >
    <div class="form w-100">
      <!--begin::Heading-->
      <div
        class="d-flex flex-center flex-column flex-column-fluid p-0 pb-lg-10"
      >
        <!--begin::Logo-->
        <a href="#" class="mb-0">
          <img alt="Logo" src="media/logos/Incline_Primary_Logo.svg" />
        </a>
        <!--end::Logo-->

        <router-view></router-view>
      </div>
      <div class="text-center mb-5">
        <!--begin::Title-->
        <h1 class="text-dark mb-3">Success!</h1>
        <h4>Your survey has been submitted. Thank you.</h4>
        <!--end::Title-->
      </div>
      <!--begin::Heading-->
    </div>
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "behavioral-survey-success",
});
</script>
